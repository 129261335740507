import React, { useContext, useEffect, useState } from "react";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { getUser } from "../../utils/auth";

import SEO from "../seo";
import moment from "moment";

import { GlobalDispatchContext } from "../../context/GlobalContextProvider";

function ScreenerPage() {
  const dispatch = useContext(GlobalDispatchContext);
  const user = getUser();
  const { uid } = user;

  const [data, setData] = useState([]);
  const [userAuthorized, setUserAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const SPREADSHEET_ID = process.env.GATSBY_SPREADSHEET_ID;
  const SHEET_ID = process.env.GATSBY_SHEET_ID;
  const CLIENT_EMAIL = process.env.GATSBY_CLIENT_EMAIL;
  const PRIVATE_KEY = process.env.GATSBY_PRIVATE_KEY;
  const USERS_SHEET_ID = process.env.GATSBY_USERS_SHEET_ID;

  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

  useEffect(() => {
    dispatch({ type: "SET_NAMES", payload: [] });
    dispatch({ type: "SET_SUBMITTED", payload: false });
    dispatch({ type: "SET_SUCCESS", payload: false });
  }, []); //eslint-disable-line

  const getNextDate = (day) => {
    // const dayINeed = 0; // for Sunday
    const today = moment().isoWeekday();
    // if we haven't yet passed the day of the week that I need:
    if (today <= day) {
      // then just give me this week's instance of that day
      return moment().isoWeekday(day);
    } else {
      // otherwise, give me *next week's* instance of that same day
      return moment().add(1, "weeks").isoWeekday(day);
    }
  };

  const getPreviousDate = (day) => {
    // const dayINeed = 0; // for Sunday
    const today = moment().isoWeekday();
    // if we  passed the day of the week that I need:
    if (today > day) {
      // then just give me this week's instance of that day
      return moment().isoWeekday(day);
    } else {
      // otherwise, give me *last week's* instance of that same day
      return moment().subtract(1, "weeks").isoWeekday(day);
    }
  };

  async function fetchData() {
    setLoading(true);
    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY.replace(/\\n/g, "\n"),
      });

      // loads document properties and worksheets
      await doc.loadInfo();

      const sheet = doc.sheetsById[SHEET_ID];
      const rows = await sheet.getRows();

      const sheet2 = doc.sheetsById[USERS_SHEET_ID];
      const userRows = await sheet2.getRows();

      setData(rows);
      setUserAuthorized(
        userRows.find((row) => row.uid === uid).screener === "TRUE"
      );

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error("Error: ", e);
    }
  }

  useEffect(() => {
    setSelectedDate(
      moment(getNextDate(new Date().getDay() === 6 ? 6 : 0)).format(
        "YYYY-MM-DD"
      )
    );
    fetchData();
  }, []); //eslint-disable-line

  const filteredData = data
    .filter((row) => {
      return moment(row["Date Applied"], "MM/DD/YYYY").isSame(
        moment(selectedDate),
        "day"
      );
    })
    .filter((row) => {
      if (searchValue === "") {
        return true;
      }
      const match = `${row["First Name"]} ${row["Last Name"]}`
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .replace(/đ/gi, "d")
        .includes(
          searchValue
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .replace(/đ/gi, "d")
        );

      return match;
    })
    .sort((a, b) => {
      if (a["Last Name"] === b["Last Name"]) {
        return a["First Name"].localeCompare(b["First Name"]);
      }
      return a["Last Name"].localeCompare(b["Last Name"]);
    });

  return (
    <>
      <SEO
        keywords={[`Don Bosco`, `COVID`, `screening`]}
        title="DB COVID Screening"
      />

      <div className="container mx-auto py-6 px-4 md:px-16 ">
        <div className="flex flex-col ">
          <div className="flex w-full flex-col md:flex-row mb-5 md:mb-0">
            <div className="flex-1 mx-2">
              <label
                htmlFor="search"
                className="block mb-2 text-xs font-bold uppercase"
              >
                Search
              </label>
              <div className="flex mb-6">
                <input
                  name="search"
                  id="search"
                  className="w-full form-input"
                  placeholder="Search"
                  autoComplete="off"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <button
                  type="button"
                  className="h-full bg-gray-700 hover:bg-gray-600 shadow text-white px-4 py-2 ml-2 my-auto rounded transition duration-100 flex items-center"
                  onClick={() => setSearchValue("")}
                >
                  Clear
                </button>
              </div>
            </div>
            <div className="flex items-baseline">
              <button
                type="button"
                className="h-10 bg-teal-700 hover:bg-teal-600 shadow text-white px-4  ml-2 my-auto rounded transition duration-100 flex items-center"
                onClick={() => fetchData()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                </svg>
              </button>

              <div className="flex-2 mx-2 my-auto">
                {/* <label
                  htmlFor="date"
                  className="block mb-2 text-xs font-bold uppercase"
                >
                  Date
                </label> */}
                <select //eslint-disable-line
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer"
                  id="selectedDate"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                >
                  {/* if today is not saturday or sunday */}
                  {moment().isoWeekday() !== 6 &&
                  moment().isoWeekday() !== 7 ? (
                    <option value={moment().format("YYYY-MM-DD")}>
                      {moment().format("dddd, MMMM D")}
                    </option>
                  ) : null}

                  <option value={moment(getNextDate(0)).format("YYYY-MM-DD")}>
                    {moment(getNextDate(0)).format("dddd, MMMM D")}
                  </option>
                  <option value={moment(getNextDate(6)).format("YYYY-MM-DD")}>
                    {moment(getNextDate(6)).format("dddd, MMMM D")}
                  </option>
                  <option
                    value={moment(getPreviousDate(0)).format("YYYY-MM-DD")}
                  >
                    {moment(getPreviousDate(0)).format("dddd, MMMM D")}
                  </option>
                  <option
                    value={moment(getPreviousDate(6)).format("YYYY-MM-DD")}
                  >
                    {moment(getPreviousDate(6)).format("dddd, MMMM D")}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="table-auto text-left w-full border-collapse">
              <thead className="flex flex-col items-center justify-between w-full">
                <tr className="flex w-full">
                  <th className="flex-1 py-3 px-2 sm:px-4 bg-gray-200 font-bold uppercase text-xs md:text-sm text-gray-700 border-b border-gray-300">
                    First Name
                  </th>
                  <th className="flex-1 py-3 px-2 sm:px-4 bg-gray-200 font-bold uppercase text-xs md:text-sm text-gray-700 border-b border-gray-300">
                    Last Name
                  </th>
                  <th className="flex-1 py-3 px-2 sm:px-4 bg-gray-200 font-bold uppercase text-xs md:text-sm text-gray-700 border-b border-gray-300">
                    Status
                  </th>
                  <th className="flex-1 py-3 px-2 sm:px-4 bg-gray-200 font-bold uppercase text-xs md:text-sm text-gray-700 border-b border-gray-300">
                    Class
                  </th>
                </tr>
              </thead>

              <tbody className="flex flex-col items-center justify-between overflow-y-scroll w-full tableheight">
                {loading ? (
                  <tr className="w-full flex">
                    <td className="h-36 bg-gray-100 w-full flex items-center">
                      <div className="loader">Loading...</div>
                    </td>
                  </tr>
                ) : userAuthorized ? (
                  filteredData.map((row) => {
                    return (
                      <tr
                        key={`${row["First Name"]}${
                          row["Last Name"]
                        }-${Math.floor(Math.random() * 1000)}`}
                        className="hover:bg-gray-200 flex w-full dont-shrink"
                      >
                        <td className="flex-1 py-2 px-2 sm:px-4 border-b border-gray-200">
                          {row["First Name"]}
                        </td>
                        <td className="flex-1 py-2 px-2 sm:px-4 border-b border-gray-200">
                          {row["Last Name"]}
                        </td>
                        <td className="flex-1 py-2 px-2 border-b border-gray-200 flex items-center">
                          <span
                            className={`text-gray-100 font-light sm:font-bold py-1 px-3 rounded text-xs ${
                              row["Form Status"] === "TRUE"
                                ? "bg-green-500"
                                : "bg-red-500"
                            } `}
                          >
                            {row["Form Status"] === "TRUE"
                              ? "APPROVED"
                              : "DENIED"}
                          </span>
                        </td>
                        <td className="flex-1 w-10 py-2 px-2 border-b border-gray-200 flex items-center">
                          {/* {moment(selectedDate).day() === 0 && row.TNTT ? ( */}
                          {row.TNTT ? (
                            <span
                              className={`text-gray-100 font-bold py-1 px-2 sm:px-3 rounded text-xs ${
                                row.TNTT.includes("AN")
                                  ? "bg-green-400"
                                  : row.TNTT.includes("TN")
                                  ? "bg-blue-500"
                                  : row.TNTT.includes("NS")
                                  ? "bg-yellow-300"
                                  : row.TNTT.includes("HS")
                                  ? "bg-yellow-700"
                                  : row.TNTT.includes("HT")
                                  ? "bg-red-700"
                                  : ""
                              } `}
                            >
                              {row.TNTT === "HT"
                                ? row.TNTT
                                : row.TNTT.substring(3)}
                            </span>
                          ) : null}
                          {moment(selectedDate).day() === 0 && row.GL ? (
                            <span
                              className={`text-gray-100 font-bold py-1 px-2 sm:px-3 rounded text-xs ml-1 ${
                                row.GL.includes("teacher")
                                  ? "bg-gray-700"
                                  : "bg-gray-600"
                              } `}
                            >
                              {row.GL}
                            </span>
                          ) : null}
                          {moment(selectedDate).day() === 6 && row.VN ? (
                            <span
                              className={`text-gray-100 font-bold py-1 px-2 sm:px-3 rounded text-xs ml-1 ${
                                row.VN.includes("teacher")
                                  ? "bg-gray-700"
                                  : "bg-gray-600"
                              } `}
                            >
                              {row.VN}
                            </span>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="w-full flex">
                    <td className="h-36 bg-gray-100 w-full flex items-center">
                      <div className="flex flex-col w-full">
                        <div className="text-2xl mx-auto uppercase">
                          Not Authorized
                        </div>
                        <div className="mx-auto">
                          Contact Tr. Denny or Thay Thinh to grant access to
                          view student list
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default ScreenerPage;
