import React from "react";
import { navigate } from "@reach/router";
import View from "./View";
import { useState } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { setUser, isLoggedIn } from "../utils/auth";
import { useFirebase } from "gatsby-plugin-firebase";

import { GoogleSpreadsheet } from "google-spreadsheet";

const Login = () => {
  const [firebase, setFirebase] = useState();

  useFirebase((firebase) => {
    setFirebase(firebase);
  }, []);

  if (isLoggedIn()) {
    navigate(`/app/screener`);
  }

  function getUiConfig(auth) {
    return {
      signInFlow: "popup",
      signInOptions: [
        auth.GoogleAuthProvider.PROVIDER_ID,
        auth.EmailAuthProvider.PROVIDER_ID,
      ],
      // signInSuccessUrl: '/app/profile',
      callbacks: {
        signInSuccessWithAuthResult: (result) => {
          setUser(result.user);

          const SPREADSHEET_ID = process.env.GATSBY_SPREADSHEET_ID;
          const SHEET_ID = process.env.GATSBY_USERS_SHEET_ID;
          const CLIENT_EMAIL = process.env.GATSBY_CLIENT_EMAIL;
          const PRIVATE_KEY = process.env.GATSBY_PRIVATE_KEY;

          const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

          const appendSpreadsheet = async (row) => {
            try {
              await doc.useServiceAccountAuth({
                client_email: CLIENT_EMAIL,
                private_key: PRIVATE_KEY.replace(/\\n/g, "\n"),
              });
              // loads document properties and worksheets
              await doc.loadInfo();

              const sheet = doc.sheetsById[SHEET_ID];
              await sheet.addRow(row);
            } catch (e) {
              console.error("Error adding new user: ", e);
            }
          };

          async function fetchData(user) {
            try {
              await doc.useServiceAccountAuth({
                client_email: CLIENT_EMAIL,
                private_key: PRIVATE_KEY.replace(/\\n/g, "\n"),
              });

              // loads document properties and worksheets
              await doc.loadInfo();

              const sheet = doc.sheetsById[SHEET_ID];
              const rows = await sheet.getRows();

              const existingUsers = rows.map((row) => row.uid);
              if (!existingUsers.includes(user.uid)) {
                console.log("user doesn't exist. creating user..");
                const newUserRow = {
                  uid: user.uid,
                  displayName: user.displayName,
                  email: user.email,
                  screener: false,
                };
                appendSpreadsheet(newUserRow);
              }
            } catch (e) {
              console.error("Error checking existing users: ", e);
            }
          }
          fetchData(result.user);

          navigate("/app/screener");
        },
      },
    };
  }

  return (
    <View title="Log In">
      <p className="mb-5">
        Screeners: Sign in or create an account using one of the following
        options.
      </p>
      {firebase && (
        <StyledFirebaseAuth
          uiConfig={getUiConfig(firebase.auth)}
          firebaseAuth={firebase.auth()}
        />
      )}
    </View>
  );
};

export default Login;
