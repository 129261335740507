import React from "react";
import { Router } from "@reach/router";
import Layout from "../components/layout";
import Profile from "../components/Profile";
import Login from "../components/Login";
import SEO from "../components/seo";
import PrivateRoute from "../components/PrivateRoute";
import ScreenerPage from "../components/ScreenerPage/ScreenerPage";

const App = () => (
  <Layout>
    <SEO
      keywords={[`Don Bosco`, `COVID`, `screening`]}
      title="DB COVID Screening"
    />
    <Router basepath="/app">
      <PrivateRoute path="/profile" component={Profile} />
      <PrivateRoute path="/screener" component={ScreenerPage} />
      <Login path="/login" />
    </Router>
  </Layout>
);

export default App;
